import { FormProvider, useForm } from "react-hook-form";
import Input from "./Input";
import { useState } from "react";
let initialState = {};
const Terms = (props) => {
  const { company, formFields, loanData } = props;
  const formRef = useForm(initialState);
  
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { handleSubmit } = formRef;
  const partnerFields = formFields[company]?.filter(
    (field) => field?.page === "terms-details"
  );
  const formValidationsObj = {};
  partnerFields?.forEach((field) => {
    formValidationsObj[field.name] = { ...field };
    initialState[field.name] = "";
  });

  const redirectToNextPage = handleSubmit((data) => {
    if (termsAccepted) {
      props?.redirectToStep(data);
    }
  });
  const renderTermsAndConditions = (companyName) => {
    return (
      <div>
        <p>I HEREBY AGREE AS FOLLOWS:</p>
        <ol>
          <li>
            By applying for a personal loan with Financing Partner, I have read,
            understood, and am willing to obtain a personal loan on the terms and
            conditions of the Financing Partner documents, subject to my
            eligibility criteria and fulfilling the necessary documentation
            process with Financing Partner.
          </li>
          <li>
            I accept the terms and conditions and request Financing Partner to
            disburse the personal loan amount to me on the said terms.
          </li>
          <li>
            I hereby declare that the entries made by me in the Application Form
            are complete and true to the best of my knowledge and based on
            records.
          </li>
          <li>
            I hereby undertake to present the original documents immediately upon
            demand by the concerned authorities of the Financing Partner.
          </li>
          <li>
            I hereby undertake to inform the Financing Partner & Company/my
            current employer about any changes in information submitted by me, in
            the Application Form and any other documents, including changes in
            addresses and phone numbers, from time to time.
          </li>
          <li>
            I hereby authorize and explicitly consent to Company validating and
            providing the information sought by the Financing Partner regarding my
            employment and payroll, including salary & deduction details.
          </li>
          <li>
            I also expressly agree and undertake to repay my loan outstanding
            through EMI mode, as specified in the loan agreement. i.e., the
            subsequent EMIs (along with interest payments, including revised
            interest rates or delayed charges, if applicable) henceforth.
          </li>
          <li>
            I further give my consent and hereby authorize {companyName} and/or
            any of its group companies, where I am employed as on date (“Current
            Employer”) to deduct the EMI amount as specified by me from my
            monthly salary payout, which will be paid to the Financing Partner by{" "}
            {companyName} and/or any of its group companies, till the time I am
            employed with the Current Employer or loan closure confirmation from
            me to the Current Employer, whichever is earlier, subject to
            applicable deductions and policies from time to time.
          </li>
          <li>
            I agree that personal loan repayment or EMI payment is always my
            responsibility and liability to repay all outstanding amounts to the
            Financing Partner on or within the stipulated dates, and {companyName}{" "}
            and/or any of its group companies is not responsible or liable for any
            outstanding dues, non-payment, or any other charges payable by me to
            the Financing Partner, in case there is a delay or default in my loan
            repayment at any point of time.
          </li>
          <li>
            I further declare and undertake that if, at any stage, my employment
            with the Company is terminated or comes to an end, the loan will be
            refunded by me to the Financing Partner directly.
          </li>
          <li>
            I hereby agree to buy Acko Group Health Insurance Policy and provide
            my express consent to the terms and conditions.
          </li>
          <li>
            I hereby declare that I am in good health and do not suffer from any
            pre-existing medical conditions or critical illness covered under the
            policy.
          </li>
          <li>
            I am consenting to share my data, including Aadhaar details, with the
            NBFC partner - Avanti Finance.
          </li>
          <li>I agree that my family income is greater than 3 lakhs.</li>
          <li>
            I give my consent to share the KYC received via Hyperverge with the
            Lender for the processing of the loan.
          </li>
        </ol>
      </div>
    );
  };

  return (
    <>
      <div className="text-2xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
        Read and accept Terms and Conditions
      </div>
      <FormProvider {...formRef}>
        <form
          onSubmit={(e) => e.preventDefault()}
          noValidate
          autoComplete="off"
          className="my-5 px-5"
        >
          <>
            {company === "entitled" ? (
              renderTermsAndConditions("Company")
            ) : (
              <Input {...formValidationsObj?.terms_conditions} customClass="h-12" />
            )}
          </>
          <div className="flex items-center my-6">
            <input
              checked={termsAccepted}
              id="default-checkbox"
              type="checkbox"
              value=""
              onChange={() => {
                setTermsAccepted((prevState) => !prevState);
              }}
              className="w-4 h-4 text-[#5A786F] bg-gray-100 border-gray-300 rounded focus:ring-[5A786F] dark:focus:ring-[5A786F] dark:ring-offset-gray-800 focus:ring-2"
            />
            <label
              htmlFor="default-checkbox"
              className="ml-2 text-sm font-normal text-[#4D4D4D]"
            >
              <span className="font-bold">I agree</span> to the terms and
              conditions
            </label>
          </div>

          <div className="my-7 mx-5 flex items-center justify-center">
            {company === 'moove' && (
              <button
                onClick={() => {
                  localStorage.clear();
                  window.location.reload();
                }}
                className="bg-[#db1d0f] text-white text-base py-2 px-7 rounded disabled:opacity-50 mr-3"
              >
                <span>Reset</span>
              </button>
            )}
            <button
              onClick={redirectToNextPage}
              disabled={!termsAccepted}
              className="bg-[#4E05FF] text-white text-base py-2 px-3 rounded disabled:opacity-50"
            >
              Continue
            </button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};
export default Terms;
