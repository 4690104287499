import Header from "../components/Header";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import Loader from "../components/Loader";
import {
  hypervergeNotify
} from "../service/loanService";

const AdhaarSuccessPage = ({ applicationId, company,consentUrl }) => {
  const search = useLocation().search;
  const [isLoading, setLoader] = useState(false);
  const status = new URLSearchParams(search).get("status") || "";
  const ref_id = new URLSearchParams(search).get("state") || "";

  const saveHypervergeData= async () => {
    try {
      setLoader(true);
      if(status == "true"){
      const hypervergeResponse = await hypervergeNotify({"refId":ref_id,"event":{"name":"digilocker.user.consent.granted","code":"user_granted_consent","source":"form"},"timeStamp":Date.now()})
      }
      else if(status == "false"){
        const hypervergeResponse = await hypervergeNotify({"refId":ref_id,"event":{"name":"digilocker.user.consent.denied","code":"user_denied_consent","source":"form"},"timeStamp":Date.now()})
      }
      setLoader(false);
    } catch (error) {
      console.log(error)
    }
    finally{
      setLoader(false);
    }
  };

  useEffect(() => {
    (async () => {
     let hypervergeResponse = await saveHypervergeData()
     return hypervergeResponse
    })();
  }, []);



    return (
      <>
      {isLoading && <Loader />}
       <Header hidePartnerLogo={true} />
       {status != "false" ? (
        <div className="text-xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
          Your application has been successfully registered with us. Thank you for giving your consent to access your Digilocker.
        </div>
      ) : (
        <div className="text-xl text-[#FF0000] font-sans font-bold my-7 px-5">
          Your application has been successfully registered with us. However, we were unable to access your documents from Digilocker. Our team will contact you shortly to assist further.
        </div>
      )}
        <div className="my-5 px-5">
          <div className="text-base text-[#4D4D4D] font-sans">
          Your Digilocker Consent form is {status != "false" ? "complete" : "incomplete"}.
          </div>
        </div>
        {/* {company !== "moove" ? (
          <div className="text-xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
            As the next step, you will receive a message on WhatsApp from us to complete the rest of the process
          </div>
        ) : (
          <div className="text-xl text-[#4D4D4D] font-sans font-bold my-7 px-5">
            Please refer to the dashboard provided by us for regular updates
          </div>
        )} */}
      </>
    );
  };
  
  export default AdhaarSuccessPage;
  